/**
 * HeaderSlim component.
 * @module components/ItaliaTheme/Header/HeaderSlim
 */

/*
 ------CUSTOMIZATIONS:
 - show TertiaryMenu and ArLogin only if it's not subsite
 - show Anontools only if it's not intranet and user is not logged in
 */

import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import {
  ParentSiteMenu,
  LanguageSelector,
  ArLogin,
  TertiaryMenu,
  FontAwesomeIcon,
} from '@italia/components/ItaliaTheme';
// import { Anontools } from '@plone/volto/components';
import config from '@plone/volto/registry';
import { Link } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';
// import { FormattedMessage } from 'react-intl';

const HeaderSlimRightZone = () => {
  const subsite = useSelector((state) => state.subsite?.data);
  const token = useSelector((state) => state.userSession.token, shallowEqual);
  const content = useSelector((state) => state.content.data, shallowEqual);

  return (
    <>
      <ParentSiteMenu />
      {!subsite && <TertiaryMenu />}
      <LanguageSelector />
      {config?.settings?.isIntranet && !token && (
        <div className="tools" style={{ paddingLeft: '10px' }}>
          <Link
            aria-label="login"
            to={`/login${
              content?.['@id']
                ? `?return_url=${flattenToAppURL(content['@id'])}`
                : ''
            }`}
          >
            <FontAwesomeIcon title="Log in" icon={['fas', 'lock-open']} />
          </Link>
        </div>
      )}
      {!config?.settings?.isIntranet && !subsite && <ArLogin />}
    </>
  );
};

export default HeaderSlimRightZone;
