/**
 * Add your config changes here.
 * @module config
 */

import userHeadSetSVG from '@package/icons/user-headset-duotone.svg';
import sitemapSVG from 'design-comuni-plone-theme/icons/sitemap.svg';
import HelpEdit from '@package/components/Blocks/HelpBlock/Edit';
import HelpView from '@package/components/Blocks/HelpBlock/View';
import ProgettoView from '@package/components/View/ProgettoView/ProgettoView.jsx';
import RepeatableContentEdit from '@package/components/Blocks/RepeatableContentBlock/Edit';
import RepeatableContentView from '@package/components/Blocks/RepeatableContentBlock/View';

import applyItaliaConfig from 'design-comuni-plone-theme/config/italiaConfig';

import { addInformationsTableTemplateOptions } from '@package/config/Blocks/ListingOptions';
import { CUSTOM_FEEDBACK_MESSAGES } from '@package/components/FeedbackForm/feedbackMessages';
import {
  UOServices,
  ContentImage,
  UOStructure,
  UOContacts,
  UODocuments,
  UOWhatDoesItDo,
  UOMoreInfos,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';

import UOPresentation from '@package/components/View/UOView/UOPresentation';
import UOManagers from '@package/components/View/UOView/UOManagers';
import UOStaff from '@package/components/View/UOView/UOStaff';
import {
  InformationsTableTemplate,
  InformationsTableTemplateSkeleton,
} from '@package/components';

import addItaliaWidgets from '@package/config/widgets';

import '@plone/volto/config';

export default function applyConfig(voltoConfig) {
  const config = applyItaliaConfig(voltoConfig);
  const isMultilingual = !config.settings.disableMultilingual;
  config.settings = {
    ...config.settings,
    isMultilingual: isMultilingual,
    supportedLanguages: ['it', 'en'],
    defaultLanguage: 'it',
    // TODO: use environment variable to set matomo site id
    matomoSiteId: '1e0GwNw06l',
    matomoUrlBase: 'https://ingestion.webanalytics.italia.it/',
    // italiaThemeViewsConfig: {
    //   ...config.settings.italiaThemeViewsConfig,
    //   imagePosition: 'afterHeader', // possible values: afterHeader, documentBody
    // },
    siteProperties: {
      /*
        Per tutte le props di siteProperties, può essere definito un oggetto per i siti multilingua, strutturato in questo modo:
        {'default': _valore_della_prop, 'it': _valore_della_prop,...}
        Se l'oggetto non è strutturato in questo modo, viene usato l'oggetto stesso, altrimenti viene preso il valore della corrispondente alla prop della lingua corrente o alla prop 'default' se esiste.
      */
      ...config.settings.siteProperties,
      siteTitle: 'Azienda Unità Sanitaria Locale di Piacenza',
      siteSubtitle: '',
      parentSiteTitle: config.settings.isIntranet
        ? "Sito web pubblico dell'Azienda Usl di Piacenza "
        : 'Portale Salute Regione Emilia-Romagna',
      parentSiteURL: config.settings.isIntranet
        ? 'https://www.ausl.pc.it'
        : 'https://salute.regione.emilia-romagna.it/',
      subsiteParentSiteTitle: config.settings.isIntranet
        ? 'Portale Interno - AUSL Piacenza'
        : 'SSN Emilia-Romagna - AUSL Piacenza',
      arLoginUrl: config.settings.isIntranet ? 'login' : '/it/area-riservata/',
      markSpecialLinks: false,
      enableFeedbackForm: true,
      //arLogoutUrl: '/logout',
      // smallFooterLinks: {
      //   default: [
      //     { title: 'Privacy policy', url: '/it/privacy-policy' },
      //     { title: 'Note legali', url: '/it/note-legali' },
      //     { title: 'Mappa del sito', url: '/it/sitemap' },
      //   ],
      // },

      headerslimTertiaryMenu: config.settings.isIntranet
        ? {
            it: [
              { url: '/it/news', title: 'News' },
              { url: '/it/azienda', title: 'Azienda' },
              { url: '/it/cartellino', title: 'Cartellino' },
            ],
            en: [],
          }
        : {
            it: [
              { url: '/it/novita', title: 'Novità' },
              { url: '/it/scuole', title: 'Scuole' },
              { url: '/it/imprese-e-enti', title: 'Imprese ed Enti' },
            ],
            en: [
              { url: '/en/news', title: 'News' },
              { url: '/en/schools', title: 'Schools' },
              {
                url: '/en/companies-and-organizations',
                title: 'Companies and Organizations',
              },
            ],
          },
    },

    italiaThemeViewsConfig: {
      ...(config.settings.italiaThemeViewsConfig ?? {}),
      UnitaOrganizzativa: {
        ...(config.settings.italiaThemeViewsConfig?.['UnitaOrganizzativa'] ??
          {}),
        sections: [
          {
            /* HEADER IMAGE */

            component: ContentImage,
            props: { position: 'documentBody' },
          },

          { /* PRESENTAZIONE */ component: UOPresentation },
          { /*** COSA FA ***/ component: UOWhatDoesItDo },
          { /*** STRUTTURA ***/ component: UOStructure },
          { /*** RESPONSABILI ***/ component: UOManagers },
          { /*** STAFF ***/ component: UOStaff },
          { /*** SERVIZI ***/ component: UOServices },
          { /*** CONTATTI ***/ component: UOContacts },
          { /*** ALLEGATI E DOCUMENTI ***/ component: UODocuments },
          { /* ULTERIORI INFORMAZIONI */ component: UOMoreInfos },
        ],
      },
    },
  };

  config.views.contentTypesViews = {
    ...config.views.contentTypesViews,
    Progetto: ProgettoView,
  };
  config.widgets = {
    ...config.widgets,
    ...addItaliaWidgets(config),
  };

  //Listing variations
  config.blocks.blocksConfig.listing = {
    ...config.blocks.blocksConfig.listing,
    variations: [
      ...config.blocks.blocksConfig.listing.variations,
      {
        id: 'informazioni_table',
        isDefault: false,
        fullobjects: 1,
        title: 'Informazioni',
        template: InformationsTableTemplate,
        skeleton: InformationsTableTemplateSkeleton,
        schemaEnhancer: ({ schema, formData, intl }) => {
          addInformationsTableTemplateOptions(schema, formData, intl);
          return schema;
        },
      },
    ],

    listing_bg_colors: [], //{name:'blue', label:'Blu'},{name:'light-blue', label:'Light blue'},{name:'sidebar-background', label:'Grey'}
    listing_items_colors: [], //{name:'blue', label:'Blu'},{name:'light-blue', label:'Light blue'},{name:'sidebar-background', label:'Grey'}
  };

  config.blocks.blocksConfig.helpBlock = {
    id: 'helpBlock',
    title: 'Blocco aiuto',
    icon: userHeadSetSVG,
    group: 'common',
    view: HelpView,
    edit: HelpEdit,
    restricted: false,
    mostUsed: false,
    security: {
      addPermission: [],
      view: [],
    },
    sidebarTab: 0,
  };
  config.blocks.blocksConfig.repeatableContentBlock = {
    id: 'repeatableContentBlock',
    title: 'Contenuto da replicare',
    icon: sitemapSVG,
    group: 'common',
    view: RepeatableContentView,
    edit: RepeatableContentEdit,
    restricted: false,
    mostUsed: true,
    security: {
      addPermission: [],
      view: [],
    },
    sidebarTab: 1,
  };

  config.blocks.blocksConfig.form = {
    ...config.blocks.blocksConfig.form,
    schemaValidators: {
      ...config.blocks.blocksConfig.form.schemaValidators,
      default_from: (data) => {
        return null; //no validation for this field
      },
    },
  };

  config.settings['volto-blocks-widget'].allowedBlocks = [
    ...config.settings['volto-blocks-widget'].allowedBlocks,
    'textContentBlock',
  ];
  config.settings['volto-blocks-widget'].allowedBlocks = [
    ...config.settings['volto-blocks-widget'].allowedBlocks,
    'alert',
  ];
  config.settings['volto-blocks-widget'].allowedBlocks = [
    ...config.settings['volto-blocks-widget'].allowedBlocks,
    'info',
  ];
  config.settings['volto-blocks-widget'].allowedBlocks = [
    ...config.settings['volto-blocks-widget'].allowedBlocks,
    'listing',
  ];
  config.settings['volto-blocks-widget'].allowedBlocks = [
    ...config.settings['volto-blocks-widget'].allowedBlocks,
    'repeatableContentBlock',
  ];

  config.settings['volto-gdpr-privacy'].defaultPanelConfig.text.it.description =
    "Questo sito utilizza i cookie tecnici di navigazione e di sessione per garantire un miglior servizio di navigazione del sito, e <a href='https://webanalytics.italia.it/privacy' target='blank_'>cookies analitici</a> per raccogliere informazioni sull'uso del sito da parte degli utenti. Per saperne di più, consulta la nostra <a href='/it/cookie-policy'>Cookie Policy</a>. <p style='text-align: right;'><b>*Questo sito non utilizza i cookie di profilazione.</b></p>  ";
  config.settings['volto-gdpr-privacy'].defaultPanelConfig.last_updated =
    '2023-03-30T16:00:00+00:00';
  config.settings['volto-gdpr-privacy'].defaultPanelConfig.technical.choices =
    [];
  config.settings['volto-gdpr-privacy'].defaultPanelConfig.profiling.choices = [
    /*       {
        config_key: 'MATOMO',
        text: {
          it: {
            title: 'Matomo',
            description:
              "I cookie di Matomo sono usati per analizzare la navigazione sul sito al fine di migliorarla e fornire all'utente un'esperienza di navigazione migliore possibile.",
          },
          en: {
            title: 'Matomo',
            description:
              'Matomo cookie are used to analyze navigation on the site in order to improve it and provide the user with the best possible browsing experience.',
          },
        },
      }, */
    /*     ...config.settings[
        'volto-gdpr-privacy'
      ].defaultPanelConfig.technical.choices.filter(
        (f) => f.config_key !== 'GANALYTICS',
      ), */
  ];
  config.settings['volto-feedback'].questions.negativeFeedback = [
    'unclear_instructions',
    'wrong_instructions',
    'technical_problems',
    'other_negative',
  ];
  config.settings['volto-feedback'].questions.positiveFeedback = [
    'clear_instructions',
    'complete_instructions',
    'no_technical_problems',
    'other_positive',
  ];
  config.settings['volto-feedback'].questions.messages = {
    ...config.settings['volto-feedback'].questions.messages,
    ...CUSTOM_FEEDBACK_MESSAGES,
  };
  config.settings['volto-feedback'].additionalCommentFields = [
    /*
    Additional columns to display in comments table, for example if i customize steps.
    If component attribute is undefined, the simple value is displayed.
    Example:
    { id: 'email', label: 'Email', component: null },
    */
    // { id: 'email', label: 'Email', component: null },
    // {
    //   id: 'privacy',
    //   label: 'Privacy',
    //   component: (item) => <>{item.privacy ? 'Si' : 'No'}</>,
    // },
  ];

  return config;
}
